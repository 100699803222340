<template>
  <div class="main-rq">
<!--  <div class="crumb-rq">-->
<!--    <el-breadcrumb :separator-icon="ArrowRight">-->
<!--      <el-breadcrumb-item>-->
<!--        <a href="/mycenter">我的</a>-->
<!--      </el-breadcrumb-item>-->
<!--      <el-breadcrumb-item>我的订单</el-breadcrumb-item>-->
<!--    </el-breadcrumb>-->
<!--  </div>-->
  <div class="for-data-rq">
    <div class="for-order" v-for="(item, index) in tableData">
      <div class="order-rq">
        <div class="order-des">订单ID</div>
        <div class="order-data">{{item.jpm_order}}</div>
      </div>
      <div class="order-rq">
        <div class="order-des">订单内容</div>
        <div class="order-data">{{item.pro_name}}</div>
      </div>
      <div class="order-rq">
        <div class="order-des">下单时间</div>
        <div class="order-data"><i class="fa fa-rmb"></i> {{item.pay_time}}</div>
      </div>
      <div class="order-rq">
        <div class="order-des">订单金额</div>
        <div class="order-data"><i class="fa fa-rmb"></i> {{item.price}}</div>
      </div>
      <div class="order-rq" style="margin-bottom: 0;height: 25px;">
        <div class="order-des">支付方式</div>
        <div class="order-data">{{item.pay_type}}</div>
      </div>
    </div>
  </div>
  <div class="page-rq" v-show="total>pageSize">
    <el-pagination @change="getQueryParams" :page-size="pageSize" background small layout="prev, pager, next" :total="total" />
  </div>
  </div>
  <Footers></Footers>
</template>
<script setup lang="ts">
import Footers from "@/src/components/wap/footer/index.vue";
import type { TableInstance } from 'element-plus'
import { ArrowRight } from '@element-plus/icons-vue'
import {getUserOrderList} from "@/src/api/apiconfig/main";
import {getLocal, setLocalLogin,removeLocal} from "@/src/utils/local";

useHead({
  title:'我的订单',
  meta:[
    {hid:'keywords',name:'keywords','content':'我的订单'},
    {hid:'description',name:'description','content':'我的订单'},
  ]
})

const router = useRouter();
const total = ref(0)
const pageNumber = ref(1)
const pageSize = ref(Number(0))
const tableData = ref([])
if(!getLocal("token")){
  router.push('/mycenter')
}

//分页换页
const getQueryParams = ((number) => {
  pageNumber.value = number
  userOrderList()
  const container = document.querySelector("body")
  container.scrollTo(0, 0);
})
const userOrderList = (async ()=>{
  const response = await getUserOrderList({page:pageNumber.value})
  if(response.data.length!==0){
    tableData.value = response.data.list
    total.value = response.data.total
    pageSize.value = Number(response.data.pagesize)
  }else{
    tableData.value = []
  }
})

onMounted(() => {
  userOrderList();
});

</script>
<style scoped>
.main-rq{
  padding: 15px;
  margin-bottom: 50px;
}
.crumb-rq{
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
}
.page-rq{
  display: flex;
  justify-content: center;
  margin: 20px auto 70px auto;
  clear: both;
}
.for-order {
  background: #f1f3f9;
  padding: 15px;
  border-radius: 5px;
  margin-bottom: 10px;
}

.order-rq {
  width: 100%;
  clear: both;
  margin-bottom: 10px;
  height: 30px;
  line-height: 30px;
}
.order-status i {
  font-size: 12px;
  font-style: normal;
  padding: 7px 15px;
  border-radius: 15px;
  font-weight: bold;
}
.order-des{float: left;width: 30%;text-align: left;color: #666;}
.order-data{float: left;width: 70%;text-align: right;}

</style>